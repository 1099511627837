<template>
  <div class="page-main-bg">
    <v-container class="main-container">
      <v-toolbar
        height="70px"
        color="elevation-0"
        id="page-tollbar"
        class="mt-5 narrow-toolbar"
      >
        <tool-bar
          :breadcrumbs="breadcrumbs"
          :length="notificationsCount"
          title="التنبيهات"
        />
      </v-toolbar>
      <div>
        <v-tabs
          align-with-title
          color="primary"
          right
          show-arrows
          v-model="tab"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            :ripple="false"
            class="mr-6 rounded-15"
            v-for="notificationItem in notificationItems"
            :key="notificationItem"
          >
            {{ notificationItem }}
          </v-tab>

          <v-tabs-items
            class="mt-5 profile-tabs notification__tabs"
            v-model="tab"
          >
            <v-tab-item :eager="true" v-if="false">
              <v-card
                class="my-3 py-2 pointerCursor"
                @click="updateNotificationAsRead(notification._id)"
                :key="notification._id"
                v-for="notification in notifications"
              >
                <div class="d-flex align-center">
                  <div class="ml-4 mr-5">
                    <img
                      max-width="32"
                      max-height="32"
                      :src="icons[notification.context.serviceName]"
                      loading="lazy"
                    />
                  </div>
                  <div class="w-100">
                    <div class="d-flex align-center">
                      <div>
                        <h4 class="font-14 mb-0 mt-1">
                          {{ notification.title }}
                        </h4>
                      </div>
                      <div
                        class="circle mr-2 mt-2"
                        v-if="!notification.isRead"
                      ></div>
                      <v-spacer></v-spacer>
                    </div>
                    <h6 class="content font-12 grey--text mt-0">
                      {{ notification.body }}
                    </h6>
                  </div>
                  <div
                    class="font-12 mx-1 md-18 remove__notification__icon"
                    :class="!notification.isRead ? 'brub--flag' : ''"
                  >
                    <span
                      class="material-icons md-18"
                      @click.stop="deleteNotification(notification._id)"
                      >close</span
                    >
                    <span>{{
                      timeDifferenceCalc(notification.createdAt)
                    }}</span>
                  </div>
                  <!-- close noti icon  -->
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item :eager="true">
              <loader
                v-if="notificationLoading"
                v-bind="loaderProps"
                class="mt-2"
              />
              <v-col v-else-if="!notifications.length">
                <v-sheet
                  class="pa-10 d-flex align-center justify-center flex-column bg-primary"
                  height="60vh"
                >
                  <v-img
                    min-width="80"
                    max-height="95"
                    :src="notificationIcon"
                  />
                  <h3 class="grey--text text--darken-3 my-0 mt-5 mb-5">
                    لا توجد تنبيهات في الوقت الحالي
                  </h3>
                  <router-link to="/dashboard">
                    <v-btn
                      color="primary"
                      class="font-weight-bold fast-actions-btn pa-5 font-12"
                    >
                      <span class="d-flex align-center">
                        <v-img
                          class="ml-2"
                          max-width="16"
                          max-height="16"
                          :src="home"
                        ></v-img>
                        <h4 class="font-12 white--text">الرئيسيه</h4>
                      </span>
                    </v-btn>
                  </router-link>
                </v-sheet>
              </v-col>
              <template v-else>
                <v-card
                  class="my-3 py-2 pointerCursor"
                  @click="updateNotificationAsRead(notification._id)"
                  :key="notification._id"
                  v-for="notification in notifications"
                >
                  <div class="d-flex align-center">
                    <div class="ml-4 mr-5">
                      <img
                        max-width="32"
                        max-height="32"
                        :src="icons[notification.context.serviceName]"
                        loading="lazy"
                      />
                    </div>
                    <div class="w-100">
                      <div class="d-flex align-center">
                        <div>
                          <h4 class="font-14 mb-0 mt-1">
                            {{ notification.title }}
                          </h4>
                        </div>
                        <div
                          class="circle mr-2 mt-2"
                          v-if="!notification.isRead"
                        ></div>
                        <v-spacer></v-spacer>
                      </div>
                      <h6 class="content font-12 grey--text mt-0">
                        {{ notification.body }}
                      </h6>
                    </div>
                    <div
                      class="font-12 mx-3 md-18 remove__notification__icon line-height-1"
                      :class="!notification.isRead ? 'brub--flag' : ''"
                    >
                      <span
                        class="material-icons md-18"
                        @click.stop="deleteNotification(notification._id)"
                        >close</span
                      >
                      <span>{{
                        timeDifferenceCalc(notification.createdAt)
                      }}</span>
                    </div>
                    <!-- close noti icon  -->
                  </div>
                </v-card>
              </template>
            </v-tab-item>
            <v-tab-item :eager="true">
              <loader
                v-if="notificationLoading"
                v-bind="loaderProps"
                class="mt-2"
              />
              <v-col v-else-if="!notificationsDeleted.length">
                <v-sheet
                  class="pa-10 d-flex align-center justify-center flex-column bg-primary"
                  height="60vh"
                >
                  <v-img
                    min-width="80"
                    max-height="95"
                    :src="notificationIcon"
                  />
                  <h3 class="grey--text text--darken-3 my-0 mt-5 mb-5">
                    لا توجد تنبيهات في الوقت الحالي
                  </h3>
                  <router-link to="/dashboard">
                    <v-btn
                      color="primary"
                      class="font-weight-bold fast-actions-btn pa-5 font-12"
                    >
                      <span class="d-flex align-center">
                        <v-img
                          class="ml-2"
                          max-width="16"
                          max-height="16"
                          :src="home"
                        ></v-img>
                        <h4 class="font-12 white--text">الرئيسيه</h4>
                      </span>
                    </v-btn>
                  </router-link>
                </v-sheet>
              </v-col>
              <template v-else>
                <v-card
                  class="my-3 py-2 pointerCursor"
                  :key="notification._id"
                  v-for="notification in notificationsDeleted"
                >
                  <div class="d-flex align-center">
                    <div class="ml-4 mr-5">
                      <img
                        max-width="32"
                        max-height="32"
                        :src="icons[notification.context.serviceName]"
                        loading="lazy"
                      />
                    </div>
                    <div class="w-100">
                      <div class="d-flex align-center">
                        <div>
                          <h4 class="font-14 mb-0 mt-1">
                            {{ notification.title }}
                          </h4>
                        </div>
                        <v-spacer></v-spacer>
                      </div>
                      <h6 class="content font-12 grey--text mt-0">
                        {{ notification.body }}
                      </h6>
                    </div>
                    <div
                      class="font-12 mx-3 md-18 remove__notification__icon line-height-1"
                    >
                      <span>{{
                        timeDifferenceCalc(notification.createdAt)
                      }}</span>
                    </div>
                    <!-- close noti icon  -->
                  </div>
                </v-card>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>
      <!-- Notification Alert -->
      <sendNotificationModal
        :dialogState="NotificationModalDialogState"
        @changeDialogState="NotificationModalDialogState = $event"
        @closed-add-dialog="NotificationModalDialogState = false"
      />
      <!-- Pagination -->
      <div class="text-center mt-6 mb-5" v-if="pagination.pagesCount > 1">
        <Pagination :pagination="pagination" @onChange="paginationFunction" />
      </div>
    </v-container>
    <!-------FastActions---------->
  </div>
</template>

<script>
import config from '@/config'
import { mapMutations } from 'vuex'
import '@/styles/material-dashboard/_properties.scss'
import sendNotificationModal from '@/components/modals/sendNotificationModal.vue'
import ToolBar from '../components/listing/ToolBar'
import { notificationsPage } from '@/mixins/notifications'
import timeDifferenceCalc from '@/mixins/timeDifferenceCalc.js'
import notificationsIcon from '@/config/notifications.js'
import Pagination from '../components/helper/pagination.vue'
import loader from '@/components/helper/loader.vue'
export default {
  components: {
    sendNotificationModal,
    ToolBar,
    Pagination,
    loader
  },
  mixins: [notificationsPage, timeDifferenceCalc],
  data: () => {
    return {
      NotificationModalDialogState: false,
      FastActions: false,
      icons: notificationsIcon.icons,
      notificationIcon: require('@/assets/img/svgs/lightBell.svg'),
      home: require('@/assets/img/svgs/homeBG.svg'),
      editPen: require('@/assets/img/svgs/editPen.svg'),
      notificationItems: ['التنبيهات الجديدة', 'التنبيهات المحذوفة'],
      breadcrumbs: [
        {
          text: 'التنبيهات',
          disabled: true
        }
      ],
      tab: 0,
      page: 1,
      gridView: true,
      dialogState: false,
      halfcircle1: require('@/assets/img/half-circle-1.svg'),
      halfcircle2: require('@/assets/img/half-circle-2.svg'),
      dots: require('@/assets/img/dots-3.svg'),
      dots2: require('@/assets/img/dots-1.svg'),
      user: JSON.parse(localStorage.getItem('tokenData'))
    }
  },
  computed: {
    properties() {
      return config.properties
    },
    loaderProps() {
      return {
        laoderClasses: 'mx-auto',
        type: 'list-item-avatar-two-line',
        cols: 12,
        numberOfLines: 10
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification', 'toggleDrawer']),
    openAddDialog() {
      this.dialogState = true
    },
    onSwitchChange(i) {
      this.items[i].loadding = true

      setTimeout(() => {
        this.items[i].loadding = false
        this.items[i].value = !this.items[i].value

        this.addNotification({
          text: 'تم تغير حالة الإشعار',
          color: 'success'
        })
      }, 1000)
    }
  },
  metaInfo() {
    return {
      title: 'الإشعارات'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';

.circle {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $primary-color;
  box-shadow: 0px 1px 4px rgba($primary-color, 0.3);
}

.content {
  max-width: 90%;
}
.remove__notification__icon {
  display: flex;
  align-items: center;
  min-width: 50px;
  flex-direction: column;
}
.line-height-1 {
  line-height: 1;
}
</style>
